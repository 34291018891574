import React, {useEffect, useState} from "react"
import { Trans, useTranslation } from "react-i18next"

import cx from "classnames"
import { Container } from "../components/container"
import SEO from "../components/seo"

import { TermsForm } from "../components/forms/terms-form"
import { useModal } from "../components/modal2"
import styles from "../components/modal2/modal.module.css"

const TermsModalForm = () => (
  <>
    <h3 className={cx("text-center", styles.modalHeaderTitle)}>
      <Trans i18nKey="forms.complaintForm.title">
        Reklamační <b>formulář</b>
      </Trans>
    </h3>
    <TermsForm btnText="Odeslat" />
  </>
)

const TermsPage = () => {
  const { t } = useTranslation();
  const { show, RenderModal } = useModal()

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //   window.dataLayer.push({
  //     event: "",
  //     pageURL: "/terms",
  //     testovani: websiteVersion,
  //   })
  // }, [websiteVersion])

  return (
    <>
      <RenderModal className="modalForm">
        <TermsModalForm />
      </RenderModal>
      <SEO title="Všeobecné obchodní podmínky" />
      <Container className="terms">
        <h1 className="text-center">{t("termsPage.title")}</h1>
        <p class="text-center">{t("termsPage.subtitle")}
        </p>

        <section>
          <Trans i18nKey="termsPage.text1">
            <h3 style={{ marginBottom: "20px" }}>I. Vymezení pojmů</h3>
            <p>
            Pro účely těchto Všeobecných obchodních podmínek mají níže uvedené termíny následující význam:
            </p>            
            <p>
            Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971,
            </p>
            <p>
            e-mail: info@nutritionpro.cz, tel: +420 226 288 200 (dále jen „Provozovatel“)
            </p>
            <p>
            Program NutritionPro je programem pravidelného zdravého stravování, který zahrnuje přípravu a dodávání denních setů jídel podle klientem vybraného a objednaného počtu denních/týdenních/měsíčních cyklů provozovatelem, a to na místo určené klientem nebo na odběrné místo. (dále jen „Program NutritionPro“)
            </p>
            <p>
            Denní set jídel je vyváženou kombinací stravy na jeden den, dodávanou klientovi Provozovatelem. Denní set jídel bude individuálně přizpůsoben na optimální příjem makroživin podle údajů z úvodního dotazníku vyplněného klientem a vstupních údajů o složení těla. Jídlo v každém denním setu je přehledně uspořádáno, označeno, zabaleno, a takto jednou za dva dni dodáváno klientovi na místo jím určeném nebo na odběrné místo.
            </p>
            <p>
            Klientem je osoba, objednávající Program NutritionPro na webovém portále www.nutritionpro.cz, jakožto osoba mající zájem o nabízené služby spočívající v pravidelném stravování formou nutričně vyvážených a hodnotných potravin. Klient se zavazuje za tyto služby uhradit provozovateli sjednanou odměnu. (dále jen „Klient“)
            </p>
          </Trans>
        </section>

        <section>
          <h3>{t("termsPage.subtitle2")}</h3>
          <ol>
            <Trans i18nKey="termsPage.list1">
              <li>
              Objednávku Programu NutritionPro lze provést následujícím způsobem:
              </li>
              <li>
              na telefonním čísle +420 226 288 200 když k uzavření kupní smlouvy dojde potvrzením objednávky ze strany Provozovatele při daném hovoru,
              </li>
              <li>
              prostřednictvím e-mailu na e-mailové adresy uvedené na internetových stránkách www.nutritionpro.cz, kdy k uzavření kupní smlouvy dojde potvrzením objednávky ze strany Provozovatele formou e-mailu, telefonicky nebo dodáním Programu NutritionPro (v případě opětovné dodávky Programu NutritionPro, kdy Klient reaguje novou objednávkou na e-mail Provozovatele oznamující blížící se konec předchozí objednávky),
              </li>
              <li>
              prostřednictvím on-line formuláře umístěného na internetových stránkách www.nutritionpro.cz, kdy k uzavření kupní smlouvy dojde telefonickým potvrzením objednávky ze strany Provozovatele.
              </li>
              <li>
              Objednávku Programu NutritionPro je třeba uskutečnit nejméně 3 pracovní dny přede dnem, na který má být Program NutritionPro dodán. Každá učiněná objednávka je závazným návrhem na uzavření kupní smlouvy.
              </li>
              <li>
              Pokud klient trpí alergií na některou z potravin, je povinen neprodleně telefonicky informovat provozovatele, přičemž bude současně dohodnut postup řešení takové situace.
              </li>
            </Trans>
          </ol>
        </section>

        <section>
          <h3>{t("termsPage.subtitle3")}</h3>
          <ol>
            <Trans i18nKey="termsPage.list2">
              <li>
              Klient je povinen sdělit Provozovateli změny objednávky, jako je zejména zrušení objednávky Programu NutritionPro či změna objednaného programu, ve lhůtě nejméně 3 pracovní dny přede dnem, na který má být Program NutritionPro dodán. Změnám, o kterých Klient informuje Provozovatele v kratší lhůtě, než je uvedeno v předchozí větě, nebude ze strany Provozovatele vyhověno, Program NutritionPro bude Klientovi řádně dodán a Klient je povinen uhradit Provozovateli cenu za takto dodaný Program NutritionPro v plné výši.
              </li>
              <li>
              Pokud Klient změní/zruší objednávku v pracovní době zákaznické podpory, tj. Po-Pá od 10:00 - 12:00 a od 13:00 - 18:00 na telefonním čísle +420 226 288 200, nejpozději však 3 celé pracovní dny přede dnem doručení (nepočítá se však samotný den doručení, tj. den doručení minus 3 celé pracovní dny), Provozovatel provede změnu/odhlášku takto změněné/zrušené objednávky. 
              </li>
            </Trans>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle4")}</h3>
          <ol>
            <Trans i18nKey="termsPage.list3">
              <li>
              Provozovatel se zavazuje vyrobit Program NutritionPro dle objednávky Klienta, Program NutritionPro Klientovi dopravit na své náklady do místa určeného Klientem a tam odevzdat Klientovi způsobem uvedeným v odst. 2 tohoto článku.
              </li>
              <li>
              Řidič rozvážející Program NutritionPro předá denní set jídel Klientovi, osobě Klientem určené nebo jiné osobě přítomné v místě určeném Klientem jako místo dodávky Programu NutritionPro. Okamžikem odevzdání Programu NutritionPro Klientovi přechází na Klienta nebezpečí škody na Programu. Pokud není na místě určeném Klientem jako místo dodání žádná osoba, které by mohl být Program NutritionPro předán, a klient není telefonicky dostupný, bude Program NutritionPro uložen na skladu a Klient bude informován o možnostech vyzvednutí.
              </li>
              <li>
              Denní set jídel je dodáván v chlazeném stavu a musí být až do jeho konzumace udržován v chladu, tedy v teplotě max. do 5 °C. Provozovatel nenese žádnou odpovědnost za jakost Programu NutritionPro, pokud po jeho odevzdání Klientovi dojde k porušení skladovacích podmínek.
              </li>
              <li>
              Denní set jídel musí být spotřebován nejpozději do dne uvedeného na štítku. Po otevření je nutné ihned spotřebovat.
              </li>
              <li>
              Denní set jídel bude na určené místo dodáván v časovém rozmezí od 17 do 22 hodin. Klient si je vědom, že v mimořádných případech se může doba dodání změnit, s ohledem na dopravní situaci a jiné obdobné události. O této změně, bude klient informován telefonicky.
              </li>
              <li>
              V případě potřeby Klienta na změnu dohodnutého času dodání na místo určené klientem, je klient tuto změnu oprávněn provést prostřednictvím emailu nebo na telefonním čísle +420 226 288 200 do 12 hodin dne dodávky denního setu jídel.
              </li>
            </Trans>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle5")}</h3>
          <ol>
            <Trans i18nKey="termsPage.list4">
              <li>
              Provozovatel není zodpovědný za vady způsobené: neoprávněným zásahem, nevhodným skladováním, neodbornou manipulací, v důsledku použití zboží k jiným než určeným účelům, vlivem živelné pohromy nebo zásahem vyšší moci po předání zboží Klientovi.
              </li>
              <li>
              Při převzetí zboží je kupující povinen zkontrolovat, zda dodané zboží nevykazuje vady způsobené přepravou. Pokud ano, je kupující povinen zboží nepřevzít a informovat o tom neprodleně prodávajícího prostřednictvím emailu na adrese: info@nutritionpro.cz prostřednictvím formuláře {" "}
                <button onClick={show}>ODKAZ ZDE</button>.{" "}Kupující je dále povinen dodané zboží bez zbytečného prodlení zkontrolovat, a to především dodané množství a druh dodaného zboží. V případě zjištěných nedostatků, je potřeba o tom neprodleně informovat. Na pozdější reklamace nebude brán zřetel.
              </li>
              <li>
              Při reklamaci kvality jídla si reklamované zboží vyzvedne řidič Prodávajícího. Podmínkou reklamaci kvality jídla je, že nesmí chybět více jak 10 % z porce jídla. Jestliže reklamaci prodávající posoudí jako oprávněnou, bude vyřešena buďto výměnou, a to za jiné jídlo ve stejné cenové hladině, nebo vrácením kupní ceny v další objednávce.
              </li>
              <li>
              Klient je povinen oprávněnost svého nároku, který uplatní prostřednictvím formuláře dle odst. 2 výše doložit také přiloženou fotografií, která dokládá namítanou vadu.
              </li>
            </Trans>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle6")}</h3>
          <ol>
            <Trans i18nKey="termsPage.list5">
              <li>
              Klient se zavazuje objednaný Program NutritionPro v místě jím určeném od Provozovatele převzít, a uhradit Provozovateli za tento Program řádně a včas Kupní cenu.
              </li>
              <li>
              Provozovatel vyúčtuje Kupní cenu Klientovi fakturou s datem splatnosti do 7 dnů ode dne vystavení faktury. Provozovatel je oprávněn vystavit Klientovi fakturu v den, kdy si Klient Program NutritionPro objedná a zašle Klientovi fakturu e-mailem.
              </li>
              <li>
              Klient uhradí Provozovateli Kupní cenu na základě vystavené faktury bezhotovostním převodem na bankovní účet Provozovatele pod variabilním symbolem určeným ve faktuře.
              </li>
              <li>
              Klient uhradí Provozovateli Kupní cenu na základě vystavené faktury bezhotovostním převodem na bankovní účet Provozovatele pod variabilním symbolem určeným ve faktuře.
              </li>
              <li>
              Kupní cena považuje za uhrazenou okamžikem jejího připsání na bankovní účet Provozovatele.
              </li>
              <li>
              V případě prodlení s úhradou Kupní ceny může Provozovatel požadovat po Klientovi zaplacení úroku z prodlení.
              </li>
              <li>
              V případě prodlení s úhradou Kupní ceny může Provozovatel odmítnout dodávku objednaného Programu až do úplné úhrady Kupní ceny za předchozí dodávky Programu NutritionPro.
              </li>
              </Trans>
          </ol>
        </section>
        <section>
          <h3>{t("termsPage.subtitle7")}</h3>
          <ol>
          <Trans i18nKey="termsPage.list6">
            <li>
            Veškeré podmínky smlouvou výslovně neupravené se řídí obecně závaznými právními předpisy České republiky, zejména ustanoveními zákona č. 89/2012 Sb., občanského zákoníku, v platném znění.
            </li>
            <li>
            Tyto závazné smluvní podmínky jsou platné a účinné ode dne jejich zveřejnění na internetových stránkách provozovatele. O jakýchkoliv změnách nebo doplnění těchto závazných smluvních podmínek bude provozovatel klienty předem informovat. V případě, ukáže-li se následně některé z ustanovení těchto smluvních podmínek jako neplatné či neúčinné, neznamená tato skutečnost neplatnost smluvních podmínek jako celku. Provozovatel se zavazuje takovéto neplatné či neúčinné ujednání nahradit jiným ujednáním a informovat o této změně klienta. Pokud klient neprojeví se změnou nesouhlas do 30 dnů ode dne jejího oznámení, má se za to, že se změnou smluvních podmínek souhlasí.
            </li>
            <li>
            Akceptací těchto smluvních podmínek klientem se považují za závazně sjednané mezi ním a provozovatelem a klient nemá k obsahu Smlouvy a smluvních podmínek žádných výhrad.
            </li>
            </Trans>
          </ol>
        </section>
      </Container>
    </>
  )
}

export default TermsPage
